.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title {
    margin-top: 50px; 
    font-family: 'Arvo', serif;
    color:#003153;
    margin-bottom: 10vh;
  }
  
  
  .gridContainer {
    display: flex;
    justify-content: center;
  }
  